<template>
  <div>
    <section id="login" class="section section-secondary jpadding">
      <div class="jcard jcard-main jcard-nohover">
        <div class="brand mb-3">
          <h3>NEWS ARTICLE</h3>
          <p v-if="editMode">
            <small>Update existing news article.</small>
          </p>
          <p v-else><small>Add a news article.</small></p>
        </div>

        <!-- general -->
        <div class="card-title mt-3">
          <h3>
            <small><i class="fal fa-newspaper"></i></small> General
          </h3>
        </div>
        <div class="form-group">
          <label for="name"> <i class="fal fa-tag"></i> Title</label>
          <input
            type="text"
            v-bind:class="{
              'form-control': true,
              'is-invalid': !validInput(editData.title) && bluredTitle
            }"
            v-on:blur="bluredTitle = true"
            v-model="editData.title"
          />
          <div class="invalid-feedback">
            Please enter a valid title for the news article.
          </div>
        </div>

        <!-- category -->
        <div class="form-row">
          <div class="col-md form-group">
            <label for="status"><i class="fal fa-list"></i> Category</label>
            <b-form-select
              v-model="editData.category"
              :options="$C.getFormattedOptionsArray($C.NEWS_CATEGORIES)"
            ></b-form-select>
            <div class="invalid-feedback">
              Please select a valid category.
            </div>
          </div>

          <!-- status -->
          <div class="col-md form-group">
            <label for="status"><i class="fal fa-eye"></i> Status</label>
            <b-form-select
              v-model="editData.published"
              :options="$C.getFormattedBoolOptionsArray($C.STATUS.NEWS_STATUS_INDEX)"
            ></b-form-select>
            <div class="invalid-feedback">
              Please select a valid status.
            </div>
          </div>
        </div>

        <!-- text box -->
        <div class="form-group">
          <label for="text"><i class="fal fa-comment-alt"></i> Text</label>
          <textarea
            type="text"
            v-bind:class="{
              'form-control': true,
              'is-invalid': !validInput(editData.text) && bluredText
            }"
            v-on:blur="bluredText = true"
            v-model="editData.text"
          />
          <div class="invalid-feedback">
            Please enter a short description text.
          </div>
        </div>

        <!-- permissions -->
        <div class="card-title mt-5">
          <h3>
            <small><i class="fal fa-lock-alt"></i></small> Permissions
          </h3>
        </div>

        <div>
          <b-form-group label="Select who will see this news article:" v-slot="{ ariaDescribedby }">
            <b-form-checkbox-group
              id="permissions-checkbox-group"
              v-model="editData.permissions"
              :options="options"
              :aria-describedby="ariaDescribedby"
              name="permissions"
            ></b-form-checkbox-group>
          </b-form-group>
        </div>

        <!-- alert -->
        <b-alert v-if="showInputError" show variant="danger"
          ><i class="fad fa-exclamation-circle"></i>
          {{ validationMsg }}
        </b-alert>

        <!-- save btn -->
        <div class="btn-container text-center mt-5 ">
          <button class="jbtn jbtn-blue" value="Add" @click="updateNews">
            <i class="fad fa-check"></i> Save
          </button>
        </div>

        <!-- pdf upload -->
        <div v-if="editMode">
          <div class="card-title mt-5">
            <h3>
              <small><i class="fal fa-file-alt"></i></small> PDF File
            </h3>
          </div>

          <div v-if="editData.hasPdf" id="view-pdf">
            <div class="jcard-mini text-center">
              <button class="jbtn-text" @click="openPdf"><i class="fa fa-file-pdf fa-3x"></i></button>
              <p>
                <button class="jbtn-text text-danger mt-2" @click="deletePdf">
                  <i class="fa fa-trash"></i>
                </button>
              </p>
            </div>
          </div>

          <div id="upload-pdf" class="jcard-mini text-center mt-4">
            <div v-if="!isUploadingPdf">
              <p class="mb-3"><i class="fal fa-cloud-upload"></i> Upload a PDF to the Cloud:</p>
              <!-- <input type="file" @change="previewImage" accept="application/pdf" /> -->
              <b-form-file
                accept="application/pdf"
                v-model="pdfFile"
                :state="null"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
              ></b-form-file>
            </div>
            <div v-if="isUploadingPdf">
              <p class="mb-3">
                <i class="fal fa-cloud-upload"></i> Please wait while the PDF is being uploaded:
              </p>
              <p>
                Progress: {{ uploadValue.toFixed() + "%" }}
                <progress id="progress" :value="uploadValue" max="100"></progress>
              </p>
            </div>
            <div v-if="pdfFile != null && !isUploadingPdf">
              <button class="jbtn jbtn-icon mt-3" @click="uploadPdf"><i class="fal fa-upload"></i></button>
            </div>
          </div>
        </div>

        <!-- cover image -->
        <div v-if="editMode">
          <!-- title -->
          <div class="card-title mt-5">
            <h3>
              <small><i class="fal fa-image"></i></small> Cover Image
            </h3>
          </div>
          <!-- switch -->
          <div class="text-center">
            <label><i class="fal fa-image text-blue"></i> Default cover</label>
            <label class="switch ml-3 mr-3">
              <input type="checkbox" v-model="editData.customCover" @click="updateNewsCoverMode()" />
              <div>
                <span></span>
              </div>
            </label>
            <label><i class="fal fa-upload text-blue"></i> Upload image</label>
          </div>
          <!-- default cover -->
          <div v-if="!editData.customCover" class="d-flex mt-3">
            <div class="default-cover-image mx-auto"></div>
          </div>

          <!-- upload cover -->
          <div v-else class="mt-3">
            <!----------------------- preview existing cover ----------------------->
            <div>
              <label for="title"><i class="fad fa-image"></i> Cover image</label>
              <div v-if="!showPictureInput" class="cover-image-preview-container">
                <div
                  class="cover-image-preview-bg"
                  :style="'background-image: url(\'' + editData.coverUrl + '\')'"
                ></div>
                <div class="d-flex">
                  <button @click="showPictureInput = true" class="jbtn jbtn-sm">
                    Change Cover
                  </button>
                  <!-- <button @click="deleteImage()" class="jbtn jbtn-sm ml-3">
                  Delete Cover
                </button> -->
                </div>
              </div>
            </div>

            <!-- picture input - upload new cover -->
            <picture-input
              v-if="showPictureInput"
              ref="pictureInput"
              @change="onImageChange"
              @remove="onImageRemove"
              :prefill="imageFile"
              :width="530"
              :height="300"
              :removable="true"
              removeButtonClass="jbtn jbtn-sm jbtn-red"
              accept="image/jpeg, image/png, image/gif"
              buttonClass="jbtn jbtn-sm"
              :customStrings="{
                upload: '<h1>Upload it!</h1>',
                drag: '<small>Drag and drop cover image here</small>'
              }"
            >
            </picture-input>

            <div
              v-if="editMode && showPictureInput && editData.hasCoverImage && !imageFile"
              class="btn-container-center"
            >
              <button @click="showPictureInput = false" class="jbtn jbtn-sm">
                Cancel
              </button>
            </div>

            <div v-if="imageFile != null && !isUploadingImg" class="text-center">
              <button class="jbtn jbtn-icon" @click="uploadImage"><i class="fal fa-upload"></i></button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import C from "@/const";
import toast from "@/assets/js/toast";
import PictureInput from "vue-picture-input";
import firebase from "@/firebase/firebaseInit";
const db = firebase.db;
const serverTimestamp = firebase.serverTimestamp;
const storage = firebase.storage;

export default {
  name: "CreateNews",
  components: {
    PictureInput
  },
  data() {
    return {
      //upload
      //changeImageMode: false,
      showPictureInput: false,
      imageFile: null,

      pdfFile: null,
      uploadValue: 0,

      //page status
      isUploadingPdf: false,
      isUploadingImg: false,

      //form error alert
      showInputError: false,
      validationMsg: "",

      //edit news data form
      editMode: false,
      valid: false,
      bluredTitle: false,
      bluredText: false,
      bluredLink: false,

      options: [
        { text: "Member", value: "member" },
        { text: "Owner", value: "owner" },
        { text: "Press", value: "press" },
        { text: "Admin", value: "admin", disabled: true }
      ],

      //news article
      newsId: "",
      editData: {
        type: 0, //category
        category: 0,
        status: 0,
        title: "",
        text: "",
        published: false,
        permissions: ["admin"],
        hasPdf: false,
        pdfUrl: "",
        //cover image
        customCover: false,
        hasCoverImage: false,
        coverUrl: "",
        createdOn: serverTimestamp,
        publishedOn: serverTimestamp
      }
    };
  },
  methods: {
    //open pdf file in new tab
    openPdf() {
      console.log("Opening PDF...");
      window.open(this.editData.pdfUrl, "_blank"); //to open in new tab
    },
    //upload PDF
    // previewImage(event) {
    //   this.uploadValue = 0;
    //   this.picture = null;
    //   this.pdfFile = event.target.files[0];
    // },

    uploadPdf() {
      console.log(this.pdfFile.type);
      //make sure only PDFs are uploaded, check for blob type, TODO: check for true MIME type
      if (this.pdfFile.type != "application/pdf") {
        toast.error("The selected file is not a PDF. Please choose a different file.");
        return;
      }
      this.uploadValue = 0;
      //this.picture = null;
      this.isUploadingPdf = true;
      const storageRef = storage
        .ref("news/" + this.newsId + ".pdf")
        .put(this.pdfFile);
      storageRef.on(
        `state_changed`,
        snapshot => {
          this.uploadValue = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        error => {
          console.log(error.message);
          toast.error("Couldn't upload PDF. " + error.message);
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then(url => {
            //update news article document and reset file input
            this.editData.hasPdf = true;
            this.editData.pdfUrl = url;
            this.pdfFile = null;
            this.updateNewsAfterPdfUpload();
            toast.success("PDF uploaded.");
          });
        }
      );
    },
    // save news document after a pdf was uploaded or deleted
    updateNewsAfterPdfUpload() {
      var docRef;
      if (this.editMode) docRef = db.collection(C.COLLECTION.NEWS).doc(this.newsId);
      else return;
      docRef
        .set(
          {
            hasPdf: this.editData.hasPdf,
            pdfUrl: this.editData.pdfUrl
          },
          { merge: true }
        )
        .then(() => {
          this.isUploadingPdf = false;
        })
        .catch(error => {
          toast.error("Couldn't update news article after PDF upload/delete. " + error.message);
          this.isUploadingPdf = false;
        });
    },
    deletePdf() {
      if (confirm("Are you sure you want to delete this asset?")) {
        // reference to the file to delete
        var storageRef = storage.ref();
        var pdfRef = storageRef.child("news/" + this.newsId + ".pdf");

        // delete the file
        pdfRef
          .delete()
          .then(() => {
            // update news doc
            this.editData.hasPdf = false;
            this.editData.pdfUrl = "";
            this.updateNewsAfterPdfUpload();
            toast.success("PDF deleted successfully!");
          })
          .catch(error => {
            toast.error("Couldn't delete PDF. " + error.message);
          });
      }
    },
    //upload image
    onImageChange() {
      console.log("New picture selected!");
      if (this.$refs.pictureInput.file) {
        this.imageFile = this.$refs.pictureInput.file;
        console.log(this.imageFile);
      } else {
        console.log("Old browser. No support for Filereader API");
      }
    },
    onImageRemove() {
      console.log("Picture removed!");
      this.imageFile = null;
    },
    uploadImage() {
      this.uploadValue = 0;
      this.isUploadingImg = true;
      const storageRef = storage
        .ref("news/" + this.newsId +"."+ this.imageFile.type)
        .put(this.imageFile);
      storageRef.on(
        `state_changed`,
        snapshot => {
          this.uploadValue = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        error => {
          console.log(error.message);
          toast.error("Couldn't upload Cover Image. " + error.message);
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then(url => {
            //update news article document and reset file input
            this.editData.hasCoverImage = true;
            this.editData.coverUrl = url;
            this.imageFile = null;
            this.showPictureInput = false;
            this.updateNewsAfterImgUpload();
            toast.success("Cover image uploaded.");
          });
        }
      );
    },
    // save news document after an image was uploaded or deleted
    updateNewsAfterImgUpload() {
      var docRef;
      if (this.editMode) docRef = db.collection(C.COLLECTION.NEWS).doc(this.newsId);
      else return;
      docRef
        .set(
          {
            hasCoverImage: this.editData.hasCoverImage,
            coverUrl: this.editData.coverUrl,
            customCover: this.editData.customCover
          },
          { merge: true }
        )
        .then(() => {
          this.isUploadingImg = false;
        })
        .catch(error => {
          toast.error("Couldn't update news article after IMG upload/delete. " + error.message);
          this.isUploadingImg = false;
        });
    },
    //delete uploaded cover image
    deleteImage() {
      if (confirm("Are you sure you want to delete this asset?")) {
        // reference to the file to delete
        //var storageRef = firebase.storage().ref();
        //var imgRef = storageRef.child("news/" + this.newsId + ".pdf");
        var httpsImgRef = storage.refFromURL(this.editData.coverUrl);

        // delete the file
        httpsImgRef
          .delete()
          .then(() => {
            // update news doc
            this.editData.hasCoverImage = false;
            this.editData.coverUrl = "";
            this.editData.customCover = false;
            // update news doc in db
            this.updateNewsAfterImgUpload();
            toast.success("Cover image deleted successfully!");
          })
          .catch(error => {
            toast.error("Couldn't delete IMG. " + error.message);
          });
      }
    },
    // update news doc after 'upload' switch was pressed
    updateNewsCoverMode() {
      //switch button display helper
      this.showPictureInput = !this.editData.hasCoverImage;

      // attention: cutomCover has not been updated by the switch yet, so need to inverse the value manually
      console.log("----- new customCover to save: " + !this.editData.customCover);

      //dont update doc to set to customCover if no cover image has been uploaded yet
      if (!this.editData.customCover && !this.editData.hasCoverImage) return;

      //update news doc
      var docRef;
      if (this.editMode) docRef = db.collection(C.COLLECTION.NEWS).doc(this.newsId);
      else return;
      docRef
        .set(
          {
            customCover: !this.editData.customCover
          },
          { merge: true }
        )
        .then(() => {
          toast.success("Cover image mode updated.");
        })
        .catch(error => {
          toast.error("Couldn't update cover image mode. " + error.message);
          this.isUploadingImg = false;
        });
    },
    // save news article data to db
    updateNews() {
      if (!this.validate()) return;
      var docRef;
      if (this.editMode) docRef = db.collection(C.COLLECTION.NEWS).doc(this.newsId);
      else docRef = db.collection(C.COLLECTION.NEWS).doc();
      docRef
        .set(this.editData, { merge: true })
        .then(() => {
          if (this.editMode) toast.success("News article edited.");
          else toast.success("New article added to news.");
        })
        .catch(error => {
          if (this.editMode) toast.error("Couldn't edit news article. " + error.message);
          else toast.error("Couldn't create news article. " + error.message);
        });
    },
    validate() {
      //Check input fields
      this.bluredTitle = true;
      if (!this.validInput(this.editData.title)) {
        this.valid = false;
        return false;
      }
      this.bluredText = true;
      if (!this.validInput(this.editData.text)) {
        this.valid = false;
        return false;
      }

      this.valid = true;
      return true;
    },
    validInput(input) {
      return input.trim().length > 0;
    },
    validNonTxtField(input) {
      return input != null && input != "";
    }
  },

  created() {
    //init

    //create new article: if no id is passed. edit article: get id and load existing article
    //get news id from params
    if (this.$route.params.id != null && this.$route.params.id != "") {
      this.newsId = this.$route.params.id;
      this.editMode = true;
    }

    //load news article in edit mode
    if (this.editMode) {
      var newsRef = db.collection(C.COLLECTION.NEWS).doc(this.newsId);
      newsRef.onSnapshot(doc => {
        console.log("NEWS BEING UPDATED...");
        if (doc.exists) {
          //this.$store.commit("setNews", doc.data());
          this.editData = doc.data();
        } else {
          // doc.data() will be undefined in this case
          console.log("News document not found. No such newsId!");
          //disable editMode and load create mode
          this.editMode = false;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
img.preview {
  width: 200px;
  height: auto;
}
.default-cover-image {
  background-image: url("~@/assets/covers/duke-cover-4k.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 480px;
  height: 270px;
}

.cover-image-preview-container {
  display: grid;
  justify-items: center;
}
.cover-image-preview-bg {
  width: 530px;
  height: 300px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #ebedf0;
  margin-bottom: 30px;
  margin-top: 15px;
}
.btn-container-center {
  margin-bottom: 20px;
}
.picture-input {
  margin-bottom: 30px;
}
</style>
